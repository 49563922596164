import type { RaygunStatic } from 'raygun4js';
import React, {
  useEffect,
  useCallback,
  type FunctionComponent,
  type ReactNode,
} from 'react';

import type { JobAdPreview } from 'src/types/JobAdPreviewItem';

import type { Environment } from '../config';

import ErrorBoundary from './ErrorBoundaries';

export interface RaygunError {
  type: 'error';
  error: Error;
  info: unknown;
}

export interface RaygunTag {
  type: 'tag';
  tags: string[];
}

const tags = new Set<string>();

let rg4js: RaygunStatic;
let pendingTasks: Array<RaygunError | RaygunTag> = [];

const RAYGUN_API_KEY = 'JwNqdRbhM7lFBdxHsSsfOA';

interface Props {
  children: ReactNode;
  jobAdPreview?: JobAdPreview;
  environment: Environment;
}

export const RaygunInitializer: FunctionComponent<Props> = ({
  children,
  jobAdPreview,
  environment,
}) => {
  const initialRaygun = useCallback(async () => {
    const module = await import(
      /* webpackMode: "eager" */
      'raygun4js'
    );

    const defaultRaygun = module.default;
    defaultRaygun('noConflict', true);
    // @ts-expect-error: raygun need to fix their types
    defaultRaygun('getRaygunInstance', (raygun: RaygunStatic) => {
      rg4js = raygun.constructNewRaygun();
      rg4js.init(RAYGUN_API_KEY);
      setRaygunTags([
        `id:${jobAdPreview?.jobSearchResultPreview.id}`,
        `env:${environment}`,
      ]);
      pendingTasks.forEach((task) => {
        if (task.type === 'error') {
          sendRaygunError(task.error, task.info);
        } else if (task.type === 'tag') {
          setRaygunTags(task.tags);
        }
      });
    });
  }, [environment, jobAdPreview?.jobSearchResultPreview.id]);

  useEffect(() => {
    initialRaygun();
  }, [initialRaygun]);

  return <ErrorBoundary>{children}</ErrorBoundary>;
};

export const sendRaygunError = async (error: Error, info?: unknown) => {
  if (rg4js !== undefined) {
    rg4js.send(error, info);
  } else {
    const task = { type: 'error' as const, error, info };
    pendingTasks = [...pendingTasks, task];
  }
};

export const setRaygunTags = (newTags: string[]) => {
  if (rg4js !== undefined) {
    newTags.forEach((tag) => {
      tags.add(tag);
    });
    rg4js.withTags(Array.from(tags));
  } else {
    const task = { type: 'tag' as const, tags: newTags };

    // Prepend task to ensure pending errors get tagged
    pendingTasks = [task, ...pendingTasks];
  }
};
