import { useTranslations } from '@vocab/react';
import {
  Box,
  ContentBlock,
  Heading,
  Stack,
  TextLink,
} from 'braid-design-system';
import type React from 'react';

import translations from 'src/locales.vocab';

import { SeekLogo } from './SeekLogo';

import * as styles from './index.css';

interface JobAdPreviewHeaderProps {
  children: React.ReactNode;
}

export const JobAdPreviewHeaderLayout = ({
  children,
}: JobAdPreviewHeaderProps) => {
  const { t } = useTranslations(translations);
  return (
    <Stack
      space="medium"
      align="center"
      data={{ testid: 'job-ad-preview-header' }}
    >
      <ContentBlock>
        <Stack space="medium">
          <Box component="span" className={styles.seekLogo} alignItems="center">
            <TextLink
              href="https://talent.seek.com.au"
              target="_blank"
              data={{ testid: 'job-ad-preview-header-seek-logo' }}
            >
              <SeekLogo />
            </TextLink>
          </Box>
          <Heading level="2" data={{ testid: 'job-ad-preview-header-text' }}>
            {t('SEEK Job Ad Preview')}
          </Heading>
          <>{children}</>
        </Stack>
      </ContentBlock>
    </Stack>
  );
};
