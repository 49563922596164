import { VideoProductPosition } from '@seek/ca-graphql-schema/types';

import type { supportedLanguages } from 'src/config';
import translations from 'src/locales.vocab';
import type {
  JobAdPreview,
  JobDetailsPreviewItem,
} from 'src/types/JobAdPreviewItem';
import type { MetropolisJobDetailsPreview } from 'src/types/MetropolisJobDetailsPreview';

const videoProductPositions: Record<
  NonNullable<JobDetailsPreviewItem['video']>['position'],
  VideoProductPosition
> = {
  above: VideoProductPosition.Top,
  below: VideoProductPosition.Bottom,
};

export const mapMetropolisJobAdPreview = (
  {
    applyUrl,
    branding,
    locationName,
    postedDate,
    salary,
    url,
    video,
    workType,
    parentCategoryName,
    subCategoryName,
    companyName,
    ...jobDetailsPreview
  }: JobDetailsPreviewItem,
  language: (typeof supportedLanguages)[number],
): MetropolisJobDetailsPreview | null => {
  const t = translations.getLoadedMessages(language);

  // It's unlikely to return null from getLoadedMessages given we have gotten the language from useLanguage.
  if (!t) {
    return null;
  }

  const seekAnzWorkTypeCodePreviews: Record<
    JobAdPreview['jobDetailsPreview']['workType'],
    string
  > = {
    FullTime: t['Full time'].format(),
    PartTime: t['Part time'].format(),
    ContractTemp: t['Contract temp'].format(),
    Casual: t.Casual.format(),
  };

  return {
    ...jobDetailsPreview,
    company: {
      name: companyName ?? t['Private Advertiser'].format(),
    },
    applyUrl: applyUrl ?? '',
    branding: branding
      ? {
          ...branding,
          assets: {
            ...branding.assets,
            cover: branding.assets.cover ?? undefined,
          },
        }
      : undefined,
    contactMatches: [],
    currencyLabel:
      typeof salary === 'object' && salary.isCurrencyLabelEnabled
        ? salary.currency
        : undefined,
    isVerified: true,
    classifications:
      subCategoryName && parentCategoryName
        ? [`${subCategoryName} (${parentCategoryName})`]
        : undefined,
    location: locationName,
    // TODO: `postedDate` is always empty currently and can be an empty string.
    // In future, we can retrieve this from the Jobs API on edits, store the
    // RFC3339 date in storage, then format and localise the value here.
    // We intentionally use `||` here to handle empty strings in historical data.
    postedDateFormatted: postedDate || t['just now'].format(),
    restrictedApplication: {},
    salary: typeof salary === 'string' ? salary : salary?.description,
    url: url ?? '',
    video: video
      ? {
          link: video.link,
          position: videoProductPositions[video.position],
        }
      : video,
    workType: seekAnzWorkTypeCodePreviews[workType],
  };
};
