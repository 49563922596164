import { Component, type ErrorInfo, type ReactNode } from 'react';

import { sendRaygunError } from './Logger';

interface Props {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    sendRaygunError(error, errorInfo);
    throw error;
  }

  public render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
