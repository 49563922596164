import { type LayoutOverride, BrandLogo } from '@seek/branding-assets';
import { useTranslations } from '@vocab/react';
import {
  Box,
  Card,
  Column,
  Columns,
  ContentBlock,
  Divider,
  Heading,
  Inline,
  List,
  Stack,
  Text,
} from 'braid-design-system';
import React from 'react';

import translations from 'src/locales.vocab';
import type { JobSearchResultPreview } from 'src/types/JobAdPreviewItem';

import { SearchResultPlaceholder } from '../SearchResultPlaceholder';

import * as styles from './index.css';
import * as layoutStyles from 'src/components/JobDetailsPreviewLayout/index.css';

interface JobSearchResultsPreviewProps {
  jobSearchResultPreview: JobSearchResultPreview;
  layout: LayoutOverride;
}

export const SearchResultsPreviewLayout = ({
  jobSearchResultPreview,
  layout,
}: JobSearchResultsPreviewProps) => {
  const containerClass =
    layout === 'desktop'
      ? layoutStyles.desktopContainer
      : layoutStyles.mobileContainer;

  const layoutStyle =
    layout === 'desktop'
      ? layoutStyles.desktopSearchResult
      : layoutStyles.mobile;

  const keySellingPoints = (
    jobSearchResultPreview.keySellingPoints ?? []
  ).filter((item) => item.length > 0);

  const showFeaturedHighlight = jobSearchResultPreview.hasFeaturedSlot;
  const { t } = useTranslations(translations);

  return (
    <ContentBlock>
      <Stack space="none" align="center">
        <Box id="search-results-preview" className={containerClass}>
          <Box className={layoutStyle}>
            <Box background="surface">
              <Stack space="xxsmall">
                <SearchResultPlaceholder lines={2} />
                <Divider />
                <Card tone={showFeaturedHighlight ? 'formAccent' : undefined}>
                  <Stack space="large">
                    <Stack space="small">
                      <Columns space="small">
                        <Column>
                          <Heading
                            level="3"
                            data={{
                              testid: 'search-results-preview-job-title',
                            }}
                          >
                            <Box component="span" className={styles.jobTitle}>
                              {jobSearchResultPreview.title}
                            </Box>
                          </Heading>
                        </Column>
                        {showFeaturedHighlight ? (
                          <Column width="content">
                            <Text
                              data={{
                                testid:
                                  'search-results-preview-premium-featured',
                              }}
                            >
                              <Box component="span" className={styles.jobTitle}>
                                {t('Featured')}
                              </Box>
                            </Text>
                          </Column>
                        ) : null}
                      </Columns>
                      <Text
                        size="large"
                        weight="regular"
                        data={{
                          testid: 'search-results-preview-company-name',
                        }}
                      >
                        {jobSearchResultPreview.companyName ??
                          t('Private Advertiser')}
                      </Text>
                    </Stack>
                    <Stack space="small">
                      {Boolean(jobSearchResultPreview.locationName) ? (
                        <Text
                          size="small"
                          data={{
                            testid: 'search-results-preview-location',
                          }}
                        >
                          {`${jobSearchResultPreview.locationName}`}
                        </Text>
                      ) : null}

                      {typeof jobSearchResultPreview.salary === 'string' ||
                      jobSearchResultPreview.salary?.description ? (
                        // TODO: revise the appearance of the SERP preview in
                        // light of the new unified two-pane layout.
                        <Text size="small">
                          {typeof jobSearchResultPreview.salary === 'string'
                            ? jobSearchResultPreview.salary
                            : jobSearchResultPreview.salary.description}
                        </Text>
                      ) : null}
                      {jobSearchResultPreview.subCategoryName &&
                      jobSearchResultPreview.parentCategoryName &&
                      layout === 'desktop' ? (
                        <>
                          <Inline align="left" space="xxsmall" alignY="center">
                            <Text
                              size="small"
                              data={{
                                testid: 'search-results-preview-classification',
                              }}
                            >
                              {`${jobSearchResultPreview.subCategoryName} (${jobSearchResultPreview.parentCategoryName})`}
                            </Text>
                          </Inline>
                        </>
                      ) : null}
                    </Stack>

                    {/**
                     * TODO: support variable ad features:
                     * - API retrieves features from Indirect Ad Products API -> Athena Hirer Products API
                     * - API stores features in DynamoDB
                     * - UI retrieves features (e.g. `searchBulletPointsIndicator`) from DynamoDB
                     */}
                    {keySellingPoints.length > 0 && (
                      <List type="bullet" space="small" size="small">
                        {keySellingPoints.map((ksp: string, index) => (
                          <Text key={`ksp-${index + 1}`}>{ksp}</Text>
                        ))}
                      </List>
                    )}

                    <Box paddingRight="xxlarge">
                      <Text size="small" tone="secondary">
                        {jobSearchResultPreview.searchDescription}
                      </Text>
                    </Box>
                  </Stack>

                  {jobSearchResultPreview.branding?.assets.logo && (
                    <Box display="flex" justifyContent="flexEnd">
                      <BrandLogo
                        placement="serp"
                        logo={jobSearchResultPreview.branding?.assets.logo}
                      />
                    </Box>
                  )}
                </Card>
                <Divider />
                <SearchResultPlaceholder />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Stack>
    </ContentBlock>
  );
};
