import { Box, Card, Stack } from 'braid-design-system';
import React from 'react';

interface Props {
  lines?: number;
}

export const SearchResultPlaceholder = ({ lines = 5 }: Props) => (
  <Card>
    <Stack space="medium">
      {[...Array(lines)].map((_l: undefined, i: number) => {
        const styles = { padding: '10px 0', width: '100%' };

        if (lines > 2 && i === 0) {
          styles.width = '66.6667%';
        }

        return (
          <Box key={`placeholder-line-${i}`} background="body" style={styles} />
        );
      })}
    </Stack>
  </Card>
);
