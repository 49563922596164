import React from 'react';

import type { UIMode } from '../../types/UIMode';
import { DescriptionMessage } from '../DescriptionMessage';
import { JobAdPreviewHeaderLayout } from '../JobAdPreviewHeaderLayout';

interface JobAdPreviewHeaderProps {
  uiMode: UIMode;
}

export const JobAdPreviewHeader = ({ uiMode }: JobAdPreviewHeaderProps) => (
  <JobAdPreviewHeaderLayout>
    <DescriptionMessage uiMode={uiMode} />
  </JobAdPreviewHeaderLayout>
);
