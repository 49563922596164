import 'braid-design-system/reset';

import type { Language, Locale } from '@seek/melways-sites';
import { VocabProvider } from '@vocab/react';
import { BraidProvider } from 'braid-design-system';
import apac from 'braid-design-system/themes/apac';
import seekJobs from 'braid-design-system/themes/seekJobs';
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import type { Environment } from 'src/config';
import type { JobAdPreview } from 'src/types/JobAdPreviewItem';

import type { UIMode } from '../types/UIMode';

import ErrorPage from './Error/ErrorPage';
import { JobAdPreviewContainer } from './JobAdPreviewContainer';
import { RaygunInitializer } from './Logger';

interface AppProps {
  jobAdPreview?: JobAdPreview;
  hasError: boolean;
  uiMode?: UIMode;
  environment: Environment;
  language: Language;
  locale: Locale;
  theme: 'apac' | 'seekJobs';
}

export default ({
  jobAdPreview,
  hasError,
  uiMode,
  environment,
  language,
  locale,
  theme,
}: AppProps) => (
  <RaygunInitializer jobAdPreview={jobAdPreview} environment={environment}>
    <VocabProvider language={language} locale={locale}>
      <BraidProvider theme={theme === 'seekJobs' ? seekJobs : apac}>
        {hasError === true ? (
          <ErrorPage uiMode={uiMode} />
        ) : (
          <Routes>
            <Route path="/oops" element={<ErrorPage uiMode={uiMode} />} />
            <Route
              path="*"
              element={
                <JobAdPreviewContainer
                  jobAdPreview={jobAdPreview}
                  uiMode={uiMode}
                />
              }
            />
          </Routes>
        )}
      </BraidProvider>
    </VocabProvider>
  </RaygunInitializer>
);
