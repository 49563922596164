import type { LayoutOverride } from '@seek/branding-assets';
import { useTranslations } from '@vocab/react';
import {
  Card,
  Stack,
  TabsProvider,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
} from 'braid-design-system';
import React, { Fragment, useState } from 'react';

import translations from 'src/locales.vocab';
import type { JobAdPreview } from 'src/types/JobAdPreviewItem';

import type { UIMode } from '../../types/UIMode';
import { JobAdPreviewHeader } from '../JobAdPreviewHeader';
import { JobDetailsPreviewLayout } from '../JobDetailsPreviewLayout';
import { PreviewLayoutSelector } from '../PreviewLayoutSelector';
import { SearchResultsPreviewLayout } from '../SearchResultsPreviewLayout';

interface JobAdPreviewContainerProps {
  jobAdPreview?: JobAdPreview;
  uiMode?: UIMode;
}

export const JobAdPreviewContainer = ({
  jobAdPreview,
  uiMode,
}: JobAdPreviewContainerProps) => {
  const [jobDetailsLayout, setJobDetailsLayout] =
    useState<LayoutOverride>('desktop');
  const [searchResultsLayout, setSearchResultsLayout] =
    useState<LayoutOverride>('desktop');
  const { t } = useTranslations(translations);
  return (
    <Fragment>
      <Card>
        <Stack space="gutter">
          <Stack space="xlarge">
            <JobAdPreviewHeader uiMode={uiMode} />
            {jobAdPreview && (
              <TabsProvider id="preview">
                <Stack space="none">
                  <Tabs label="Previews" align="center" divider="none">
                    <Tab data={{ testid: 'job-details-preview-tab' }}>
                      {t('Job details')}
                    </Tab>
                    <Tab data={{ testid: 'search-results-preview-tab' }}>
                      {t('Search results')}
                    </Tab>
                  </Tabs>
                  <TabPanels>
                    <TabPanel>
                      <PreviewLayoutSelector
                        selectedLayout={jobDetailsLayout}
                        onChangeDesktopLayout={() =>
                          setJobDetailsLayout('desktop')
                        }
                        onChangeMobileLayout={() =>
                          setJobDetailsLayout('mobile')
                        }
                      >
                        <JobDetailsPreviewLayout
                          jobDetailsPreview={jobAdPreview.jobDetailsPreview}
                          layout={jobDetailsLayout}
                        />
                      </PreviewLayoutSelector>
                    </TabPanel>
                    <TabPanel>
                      <PreviewLayoutSelector
                        selectedLayout={searchResultsLayout}
                        onChangeDesktopLayout={() =>
                          setSearchResultsLayout('desktop')
                        }
                        onChangeMobileLayout={() =>
                          setSearchResultsLayout('mobile')
                        }
                      >
                        <SearchResultsPreviewLayout
                          jobSearchResultPreview={
                            jobAdPreview.jobSearchResultPreview
                          }
                          layout={searchResultsLayout}
                        />
                      </PreviewLayoutSelector>
                    </TabPanel>
                  </TabPanels>
                </Stack>
              </TabsProvider>
            )}
          </Stack>
        </Stack>
      </Card>
    </Fragment>
  );
};
