import type { LayoutOverride } from '@seek/branding-assets';
import { useTranslations } from '@vocab/react';
import { Box, IconDesktop, IconMobile, Stack, Text } from 'braid-design-system';
import React, { Fragment, type ReactNode } from 'react';

import translations from 'src/locales.vocab';

interface PreviewLayoutSelectorProp {
  children: ReactNode;
  selectedLayout: LayoutOverride;
  onChangeDesktopLayout: () => void;
  onChangeMobileLayout: () => void;
}

export const PreviewLayoutSelector = ({
  children,
  selectedLayout,
  onChangeDesktopLayout,
  onChangeMobileLayout,
}: PreviewLayoutSelectorProp) => {
  const desktopItemTone = selectedLayout === 'desktop' ? 'link' : 'secondary';
  const mobileItemTone = selectedLayout === 'mobile' ? 'link' : 'secondary';
  const { t } = useTranslations(translations);
  return (
    <Box padding="large" background="body">
      <Stack space="large">
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            id="desktop-layout-selector"
            cursor="pointer"
            onClick={onChangeDesktopLayout}
            role="button"
          >
            <Stack space="small" align="center">
              <IconDesktop tone={desktopItemTone} />
              <Text size="small" tone={desktopItemTone}>
                {t('Desktop')}
              </Text>
            </Stack>
          </Box>
          <Box marginRight="gutter" />
          <Box
            id="mobile-layout-selector"
            cursor="pointer"
            onClick={onChangeMobileLayout}
            role="button"
          >
            <Stack space="small" align="center">
              <IconMobile tone={mobileItemTone} />
              <Text size="small" tone={mobileItemTone}>
                {t('Mobile')}
              </Text>
            </Stack>
          </Box>
        </Box>

        <Fragment>{children}</Fragment>
      </Stack>
    </Box>
  );
};
